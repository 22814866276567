import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/homepage/homepage/src/layouts/pages.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Grün`}</h1>
    <h2>{`Warum grün?`}</h2>
    <p>{`Ich bin überzeugter Grüner - schon immer gewesen. Bündnis 90 / Die Grünen sind nicht perfekt, aber sie stehen für die aus meiner Sicht ehrlichste Politik in der deutschen Parteienlandschaft:`}</p>
    <ul>
      <li parentName="ul">{`Diese Politik legt mehr Wert auf eine langfristig lebenswerte Erde für zukünftige Generationen, statt auf kurzgedachte Geschenke für ihre Wähler.`}</li>
      <li parentName="ul">{`Sie inkludiert alle Gruppen von Menschen, statt Wir gegen Die zu betonen.`}</li>
      <li parentName="ul">{`Sie geht notwendige, teils schmerzhafte Änderungen an, statt nur Geld und Arbeit zu predigen.`}</li>
    </ul>
    <p>{`Anti-demokratische, anti-europäische und anti-pluralistische Einstellungen haben zurzeit mal wieder Hochkonjunktur. Aus diesem Grund sehe ich es als meine Pflicht an, diesen Tendenzen mit politischer Arbeit die Stirn zu bieten.`}</p>
    <p>{`Ich glaube, es lohnt sich mit fast allen zu sprechen, auch mit den meisten Wählern am anderen Ende des politischen Spektrums. Denn erfahrungsgemäß eint uns doch mehr als uns trennt, trotz aller Differenzen.`}</p>
    <h2>{`Wie weiter?`}</h2>
    <p>{`Ich bin angetreten, um mich in die Stadtverordnetenversammlung meiner Heimatstadt Fürstenwalde wählen zu lassen. Die Wahl fand am 9. Juni 2024 statt. Leider haben die Grünen nur noch 2 statt zuvor 3 Sitze bekommen. Mit Platz 3 bin ich deshalb nur auf dem ersten Nachrückerplatz gelandet.`}</p>
    <p>{`Nichtsdestotrotz bin ich sachkundiger Bürger für meine Fraktion im Stadtentwicklungsausschuss und kann mich so auch ohne Stimm- und Antragsrecht in die Stadtpolitik einbringen. `}</p>
    <p>{`Meine Hauptanliegen sind:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Finanzen in grün: Lasst uns dafür sorgen, dass unsere Kommunen durch klimapolitisch richtige Entscheidungen Geld sparen und verdienen. Also zum Beispiel: mehr Wind- und Solarenergiestandorte und die Kommune an den Einnahmen beteiligen. Kommunale Unternehmen dazu verpflichten energiesparende und elektrische Geräte und Maschinen anzuschaffen. Abwärme nutzen bzw. Wärmenetze schaffen. Klarheit schaffen für Erdwärmebohrungen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Verkehr in grün: Lücken im Radwegenetz schließen. Radfahren auch für Kinder und Ältere möglich und sicher machen. ÖPNV so organisieren, dass Busse zum Beispiel am Stau vorbeikommen - und deshalb auch genutzt werden. Zugstrecken reaktivieren. Erleichterungen für Car-Sharing-Unternehmen auch auf dem Land schaffen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sozialpolitik in grün: Wohnraum für Geflüchtete zur Verfügung stellen. Housing First bei Obdachlosigkeit. Hilfen, damit Obdachlosigkeit gar nicht erst passiert.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      